<template>
  <v-container fill-height flex-column flex-nowrap justify-start align-start class="profile">
    <FormTitle>
      My Profile
    </FormTitle>
    <ProfileUserForm
      v-if="activeTab === 'info'"
      :disabled="requestInProgress"
      ref="profileForm"
      :activeTab="activeTab"
      :form.sync="profileForm"
      @fieldInput="handleUserPhotoFileInput"
      @submit="handleSubmitInfoUserButton"
      @changeTab="handleChangeTab"
    />
    <ProfileChangePasswordForm
      ref="changePasswordForm"
      v-if="activeTab === 'pass'"
      :form.sync="profilePassForm"
      :avatar.sync="profileForm"
      :activeTab="activeTab"
      :requestInProgress="requestInProgress"
      @changeTab="handleChangeTab"
      @submit="handleSubmitChangePasswordButton"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  PROFILE_FORM_FIELD_API_ALIASES,
  PROFILE_FORM_PASSWORD_FIELD_API_ALIASES,
} from '@/utils/constants';
import { fileUpload } from '@/mixins';

const ProfileUserForm = () => import('@/components/forms/Profile/ProfileUserForm');
const ProfileChangePasswordForm = () =>
  import('@/components/forms/Profile/ProfileChangePasswordForm');
const FormTitle = () => import('@/components/typography/FormTitle');

export default {
  name: 'Profile',
  components: {
    ProfileUserForm,
    FormTitle,
    ProfileChangePasswordForm,
  },
  mixins: [fileUpload],
  data: () => ({
    requestInProgress: false,
    activeTab: 'info',
    profileForm: {
      [PROFILE_FORM_FIELD_API_ALIASES.NAME]: '',
      [PROFILE_FORM_FIELD_API_ALIASES.EMAIL]: '',
      [PROFILE_FORM_FIELD_API_ALIASES.PHOTO]: '',
    },
    profilePassForm: {
      [PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.CURRENT_PASSWORD]: '',
      [PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.NEW_PASSWORD]: '',
      [PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.CONFIRM_PASSWORD]: '',
    },
  }),
  computed: {
    ...mapGetters('user', ['profile']),
  },
  created() {
    this.fillProfileForm(this.profile);
  },
  methods: {
    ...mapActions('user', ['updateUser', 'changePassword']),
    ...mapActions('files', ['uploadFile']),
    handleChangeTab(tab) {
      this.activeTab = tab;
    },
    fillProfileForm(payload) {
      this.profileForm = {
        [PROFILE_FORM_FIELD_API_ALIASES.NAME]: payload[PROFILE_FORM_FIELD_API_ALIASES.NAME] || '',
        [PROFILE_FORM_FIELD_API_ALIASES.EMAIL]: payload[PROFILE_FORM_FIELD_API_ALIASES.EMAIL] || '',
        [PROFILE_FORM_FIELD_API_ALIASES.PHOTO]: payload[PROFILE_FORM_FIELD_API_ALIASES.PHOTO] || '',
        // [PROFILE_FORM_FIELD_API_ALIASES.PHONE]: this.profile[PROFILE_FORM_FIELD_API_ALIASES.PHONE],
      };
    },
    toggleLoadingForm(payload = !this.formIsLoading) {
      this.requestInProgress = payload;
    },
    async handleUserPhotoFileInput({ field, value }) {
      if (field === PROFILE_FORM_FIELD_API_ALIASES.PHOTO) {
        try {
          const upload = await this.$store.dispatch('files/uploadFile', value);
          Object.assign(this.profileForm, {
            [PROFILE_FORM_FIELD_API_ALIASES.PHOTO]: upload,
          });
          return !!upload;
        } catch (error) {
          console.error(error);
          this.$dialog.error({ error });
          return false;
        }
      }
      return true;
    },
    async handleSubmitInfoUserButton() {
      this.toggleLoadingForm(true);
      try {
        await this.updateUser({ id: this.profile.id, form: this.profileForm });
        this.$dialog.info({
          title: 'Success',
          description: 'Profile was updated',
        });
      } catch (error) {
        this.$dialog.error({ error });
      }
      this.toggleLoadingForm(false);
    },
    async handleSubmitChangePasswordButton() {
      this.toggleLoadingForm(true);
      try {
        await this.changePassword(this.profilePassForm);
        this.$dialog.info({
          title: 'Success',
          description: 'Email letter was sent to your address',
        });
      } catch (error) {
        this.$dialog.error({ error });
      }
      this.toggleLoadingForm(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  padding: 32px 0 50px;
  width: 100%;
  max-width: 360px;
}
.profile__form-group {
  width: 100%;
}
.profile__avatar {
  margin: 34px 0 23px;
}
</style>
